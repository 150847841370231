<template>
  <transition name="slide">
    <div
      ref="notificationModal"
      v-if="isShowingNotifications"
      class="tw-absolute tw-top-[65px] tw-z-10 tw-w-full tw-max-w-full tw-bg-white lg:tw-right-5 lg:tw-top-5 lg:tw-max-w-[343px] lg:tw-rounded-lg lg:tw-shadow-lg"
    >
      <div class="tw-space-y-5 tw-border-b tw-p-5">
        <div class="tw-flex tw-items-center">
          <AppFeaturedIcon icon="bell-01" />

          <h4 class="tw-mx-2 tw-flex-grow tw-font-semibold">
            Notification ({{ store.totalUnreadNotifications ?? 0 }})
          </h4>
          <button @click="showNotifications(false)" type="button">
            <Icon name="lucide:x" class="tw-h-5 tw-w-5 tw-text-gray-500" />
          </button>
        </div>

        <div class="tw-flex tw-items-center tw-space-x-3 tw-text-sm">
          <div
            v-for="(tab, i) in tabs"
            @click.prevent="selectTab(tab.value)"
            :key="i"
            :class="[
              tab.value === activeTab
                ? 'tw-bg-gray-900 tw-text-white'
                : 'tw-bg-gray-50 tw-text-gray-900',
              ,
              activeTab === 'unread'
                ? 'tw-px-2 tw-py-2 tw-pr-4'
                : 'tw-px-4 tw-py-2',
            ]"
            class="tw-w-max tw-rounded-full tw-font-medium hover:tw-cursor-pointer"
          >
            <div class="tw-flex tw-items-center tw-space-x-2">
              <div
                v-if="tab.value === activeTab && activeTab === 'unread'"
                class="tw-grid tw-size-[24px] tw-place-content-center tw-rounded-full tw-bg-white tw-text-xs tw-font-medium tw-text-gray-900"
              >
                <span>{{ store.totalUnreadNotifications ?? 0 }}</span>
              </div>

              <span> {{ tab.title }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        ref="scrollContainer"
        @scroll="handleScroll"
        class="tw-max-h-[80vh] tw-overflow-y-auto"
      >
        <div
          v-if="filtered_items.length === 0"
          class="tw-grid tw-min-h-[100px] tw-place-content-center tw-text-center tw-text-gray-300"
        >
          you have 0 notifications
        </div>
        <div v-else v-for="(item, i) in filtered_items" :key="item?.id">
          <div
            @click="markAsRead(item)"
            :class="!item?.is_read ? 'tw-bg-gray-50' : ''"
            class="tw-space-y-3 tw-p-5 hover:tw-cursor-pointer hover:tw-bg-gray-100"
          >
            <div class="tw-flex tw-items-center tw-space-x-2">
              <div
                v-if="!item?.is_read"
                class="tw-h-2 tw-w-2 tw-rounded-full tw-bg-error-600"
              ></div>
              <div
                class="tw-inline-flex tw-cursor-pointer tw-rounded-full tw-bg-success-50 tw-px-[8px] tw-py-[4px] tw-text-xs tw-font-semibold tw-text-success-700"
              >
                <span class="tw-capitalize">
                  {{ item?.title ?? "New Notification" }}
                </span>
              </div>
            </div>

            <div
              class="tw-flex tw-w-full tw-items-center tw-text-sm tw-text-gray-500"
            >
              <div class="tw-flex tw-flex-grow tw-text-left">
                <div v-if="item?.id !== activeId" class="tw-line-clamp-1">
                  {{ item?.preview ?? "Click to view details." }}
                </div>
                <div v-else class="">{{ item?.markup_body }}</div>
              </div>
            </div>

            <div class="tw-flex tw-items-center tw-justify-end">
              <p class="tw-flex-shrink-0 tw-text-xs tw-text-gray-400">
                <span v-if="dateDiff(item?.created_at) === 0">Today</span>
                <span v-else-if="dateDiff(item?.created_at) === 1"
                  >1 day ago</span
                >
                <span v-else>{{ dateDiff(item?.created_at) }} days ago</span>
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="isLoading"
          class="tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-p-5 tw-text-center tw-text-sm tw-text-gray-300"
        >
          <span>Loading, please wait... </span>
          <span class="tw-loading tw-loading-spinner tw-loading-xs"></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";

import { useStore } from "~/store/";
const { $eventBus, $axios, $moment } = useNuxtApp();
const store = useStore();

interface INotification {
  id: string;
  title: string;
  body: string;
  preview: string;
  channel: string;
  url: string;
  is_read: boolean;
  show: boolean;
  read_by: string | null;
  read_at: string;
  sent_by: string;
  type: string;
  markup_body: string;
  meta: any | null;
  data: any | null;
  attachments: any[] | null;
  public_id: string;
  created_at: string;
}

const isLoading = ref<boolean>(false);
const activeId = ref<string>("");
const currentPage = ref<number>(1);
const lastPage = ref<number>();
const items = ref<INotification[]>([]);
const isShowingNotifications = ref<Boolean>(false);
const activeTab = ref<string>("all");
const tabs = ref<{ title: string; value: string }[]>([
  {
    title: "All Messages",
    value: "all",
  },
  {
    title: "Unread",
    value: "unread",
  },
]);

const selectTab = (tab: string) => {
  activeTab.value = tab;

  if (tab === "unread") {
    // do nothing if you've fetched last page data
    if (lastPage.value && currentPage.value >= lastPage.value) return;
    fetchNotifications(currentPage.value + 1);
  }
};

const filtered_items = computed(() => {
  if (activeTab.value === "unread") {
    return items.value.filter((item) => !item.is_read);
  }

  return items.value;
});

const scrollContainer = ref<HTMLElement | null>(null);

const fetchNotifications = async (page?: number) => {
  try {
    isLoading.value = true;

    const response = await $axios.post(
      `/schools/notifications?page=${page ?? 1}`,
    );
    const res = response?.data?.data;

    currentPage.value = res.current_page;
    lastPage.value = res.last_page;

    items.value = [...items.value, ...(res?.data ?? [])];
  } catch (error) {
    console.log(`Error fetching notifications ${error}`);
  } finally {
    isLoading.value = false;
  }
};

const fetchCount = async () => {
  try {
    isLoading.value = true;

    const response = await $axios.get(`/schools/notifications/counts`);
    const res = response?.data?.data;

    store.$patch({
      totalUnreadNotifications: res.unread_count,
    });
  } catch (error) {
    console.log(`Error fetching notifications ${error}`);
  } finally {
    isLoading.value = false;
  }
};

const markAsRead = async (data: INotification) => {
  const id = data.id;

  try {
    activeId.value = id;
    if (data.is_read) return; // notification has been read, do nothing...;
    await $axios.put(`/schools/notifications/${id}/read`);

    // update notification data
    items.value = [...items.value].map((item) => {
      if (item.id === id) {
        item.is_read = true;
      }
      return item;
    });

    await fetchCount();
  } catch (error) {
    console.log(
      `Error marking notification with id: ${id} as read::: ${error}`,
    );
  }
};

const handleScroll = () => {
  if (!scrollContainer.value) return;
  const scrollPadding = 50;
  if (
    scrollContainer.value.scrollTop +
      scrollContainer.value.clientHeight +
      scrollPadding >=
      scrollContainer.value.scrollHeight &&
    !isLoading.value
  ) {
    // do nothing if you've fetched last page data
    if (lastPage.value && currentPage.value >= lastPage.value) return;
    fetchNotifications(currentPage.value + 1);
  }
};

const dateDiff = (date: string) => {
  return $moment().diff(date, "days");
};

onMounted(() => {
  fetchNotifications(1);
  fetchCount();
});

$eventBus.$on("showNotifications", () => {
  showNotifications(true);
});

const showNotifications = (value: boolean) => {
  isShowingNotifications.value = value;
};

const notificationModal = ref(null);
onClickOutside(notificationModal, () => {
  showNotifications(false);
});
</script>

<style lang="scss" scoped>
.notification {
  position: absolute;
  z-index: 10;
  left: 0;
  background-color: red;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(120%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>
