<template>
  <div>
    <div v-if="theme === 'dark'">
      <div class="tw-space-y-2 tw-py-4">
        <DashboardRouteItem
          v-for="(route, i) in protectedRoutes"
          :id="route.id"
          :key="i"
          :route="route"
          :theme="theme"
          :is-expanded="isExpanded"
          :expanded-route-id="expandedRouteId"
          :update-expanded-route="updateExpandedRoute"
        />
      </div>
    </div>

    <div v-if="theme === 'light'">
      <div class="tw-space-y-2 tw-py-4">
        <DashboardRouteItem
          v-for="(route, i) in protectedRoutes"
          :id="route.id"
          :key="i"
          :route="route"
          :theme="theme"
          :is-expanded="isExpanded"
          :expanded-route-id="expandedRouteId"
          :update-expanded-route="updateExpandedRoute"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "~/store";
const store = useStore();
const user_role = store?.user_role?.slug ?? "none";

// In Page Types
interface ThemeType {
  theme?: "light" | "dark";
  isExpanded?: boolean;
}
interface RouteChild {
  title: string;
  to: string;
}

interface Route {
  id: string;
  icon: string;
  title: string;
  to?: string;
  showChildren?: boolean;
  children?: RouteChild[];
  roles?: string[];
}

const route = useRoute();

// Watch for route changes
watch(
  () => route.fullPath,
  () => {
    scrollY = 0;
  },
);

defineProps<ThemeType>();

const expandedRouteId = ref<string | null>(null);

const updateExpandedRoute = (id: string | null) => {
  expandedRouteId.value = id;
};

const routes: Route[] = [
  {
    id: "getting-started",
    icon: "home-01",
    title: "Getting Started",
    to: "/dashboard",
  },
  {
    id: "overview",
    icon: "trend-up-01",
    title: "Overview",
    to: "/dashboard/overview-new",
  },
  {
    id: "administration",
    icon: "shield-tick",
    title: "Administration",
    showChildren: ref(false),
    children: [
      {
        title: "Billing Accounts",
        to: "/dashboard/admin/billing-accounts",
      },
      {
        title: "Billing Managers",
        to: "/dashboard/admin/billing-managers",
      },
      {
        title: "Notices",
        to: "/dashboard/admin/notices",
      },
    ],
  },
  {
    id: "finance",
    icon: "bank",
    title: "Finance",
    showChildren: ref(false),
    children: [
      {
        title: "Wallets",
        to: "/dashboard/finance/wallets",
      },
      {
        title: "Transaction",
        to: "/dashboard/finance/transaction",
      },
      // {
      //   title: "Cowri",
      //   to: "/dashboard/finance/cowri",
      // },
    ],
  },
  {
    id: "payments",
    icon: "credit-card-refresh",
    title: "Payments",
    showChildren: ref(false),
    children: [
      {
        title: "Invoice Payments",
        to: "/dashboard/payments/invoices",
      },
      {
        title: "Settlement",
        to: "/dashboard/payments/settlement",
      },
      {
        title: "Authenticator",
        to: "/dashboard/payments/authenticator",
      },
    ],
  },
  {
    id: "bursary",
    title: "Bursary",
    icon: "file-search-02",
    showChildren: ref(false),
    children: [
      {
        title: "Billing Session",
        to: "/dashboard/bursary/billing",
      },
      {
        title: "Billing System",
        to: "/dashboard/bursary/billing-system",
      },
      {
        title: "Collections",
        to: "/dashboard/bursary/collections",
      },
      {
        title: "Invoices",
        to: "/dashboard/bursary/invoices",
      },
      {
        title: "Store",
        to: "/dashboard/bursary/store",
      },
      {
        title: "Discount & Scholarship",
        to: "/dashboard/bursary/discount-and-scholarship",
      },
    ],
  },
  {
    id: "paylink",
    icon: "link-01",
    title: "Paylink",
    to: "/dashboard/paylink",
  },
  {
    id: "rewards",
    icon: "gift-01",
    title: "Rewards",
    to: "/dashboard/rewards",
  },
];

// Filter routes based on user's role
const protectedRoutes = computed(() => {
  if (user_role === "none") {
    return routes;
  }

  if (!user_role) {
    // If no user role exists, return routes without roles or with empty roles
    return routes.filter((route) => !route.roles || route.roles.length === 0);
  } else {
    // If user role exists, filter routes based on the user role
    return routes.filter((route) => {
      if (!route.roles || route.roles.length === 0) {
        // If route has no specified roles, display it
        return true;
      } else {
        // If route has specified roles, check if user role matches any of them
        return route.roles.includes(user_role);
      }
    });
  }
});
</script>
