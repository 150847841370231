<template>
  <div
    :title="route.title"
    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded"
    :class="
      theme === 'light' ? 'light tw-text-gray-900' : 'dark tw-text-gray-200'
    "
  >
    <div v-if="route.children" class="tw-w-full">
      <div
        @click.prevent="toggleSubMenu(route.id)"
        class="tw-flex tw-w-full tw-items-center tw-justify-center tw-px-3 tw-py-2 lg:tw-justify-between"
        :class="hoverBgClass"
      >
        <div
          class="tw-flex tw-w-full tw-items-center tw-space-x-3 lg:tw-ml-0 xl:tw-justify-start"
        >
          <AppIcon :name="route.icon" />
          <h3 :class="[isExpanded ? '' : 'xl:tw-hidden']">{{ route.title }}</h3>
        </div>
        <div :class="[isExpanded ? '' : 'xl:tw-hidden']">
          <Icon
            name="ph:caret-down-bold"
            :class="[
              isExpanded ? '' : 'xl:tw-invisible',
              route.showChildren.value
                ? 'tw-rotate-180 tw-transition-all tw-duration-300 tw-ease-in-out'
                : '',
            ]"
          />
        </div>
      </div>
      <div
        v-if="isRouteExpanded(route.id)"
        :class="[isExpanded ? '' : 'xl:tw-hidden']"
      >
        <NuxtLink
          v-for="(subRoute, index) in route.children"
          :key="index"
          class="tw-flex tw-w-full tw-items-center tw-rounded tw-px-3 tw-py-2"
          :class="hoverBgClass"
          :to="subRoute.to"
          :title="subRoute.title"
          exact
        >
          <h3 class="tw-pl-8 xl:tw-block">{{ subRoute.title }}</h3>
        </NuxtLink>
      </div>
    </div>
    <div v-else class="tw-w-full">
      <NuxtLink
        class="tw-flex tw-w-full tw-items-center tw-space-x-3 tw-rounded tw-px-3 tw-py-2 lg:tw-ml-0 xl:tw-justify-start"
        :class="[hoverBgClass]"
        :to="route.to"
        :title="route.title"
        exact
      >
        <AppIcon :name="route.icon" />
        <h3 :class="[isExpanded ? '' : 'xl:tw-hidden']">{{ route.title }}</h3>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
interface RouteItemProps {
  route: any;
  theme: "light" | "dark";
  isExpanded?: boolean;
  expandedRouteId: string | null;
  updateExpandedRoute: (id: string | null) => void;
}

const props = defineProps<RouteItemProps>();

const toggleSubMenu = (id: string) => {
  props.updateExpandedRoute(id === props.expandedRouteId ? null : id);
};

const isRouteExpanded = (id: string) => {
  return id === props.expandedRouteId;
};

const hoverBgClass = computed(() => {
  return props.theme === "dark"
    ? "hover:tw-bg-gray-800"
    : "hover:tw-bg-gray-200";
});
</script>

<style lang="scss">
// Dark Mode (Desktop Side Navigation)
.dark .router-link-active {
  color: theme("colors.text");
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.800");
  width: 100%;
}
/* exact link will show the primary color for only the exact matching link */
.dark .router-link-exact-active {
  color: theme("colors.text");
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.800");
  width: 100%;
}

// Light Mode (Mobile Side Navigation)
.light .router-link-active {
  color: theme("colors.gray.700");
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.200");
  width: 100%;
}
/* exact link will show the primary color for only the exact matching link */
.light .router-link-exact-active {
  color: theme("colors.gray.700");
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.200");
  width: 100%;
}
</style>
