<template>
  <div class="tw-relative">
    <!-- Quick Action Widget Button -->
    <div
      @click.prevent="openQuickAction"
      class="tw-absolute tw-bottom-[20px] tw-right-[10px] tw-grid tw-size-[56px] tw-transform tw-cursor-pointer tw-place-content-center tw-rounded-full tw-bg-base-900 tw-text-white tw-shadow-md tw-transition-transform hover:tw-scale-110 hover:tw-shadow-lg"
    >
      <AppIcon name="zap-fast" />
    </div>

    <!-- Quick Action Main Component with Transition -->
    <transition
      name="quick-action"
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <div
        v-if="isQuickActionModal"
        ref="quickActionsContainer"
        class="tw-absolute tw-bottom-[20px] tw-right-[76px] tw-h-max tw-w-[272px] tw-rounded-xl tw-border tw-bg-white tw-shadow-lg tw-transition-all"
      >
        <div class="tw-p-5">
          <h5 class="tw-text-lg tw-font-semibold tw-text-base-700">
            Quick Actions
          </h5>
        </div>

        <div class="tw-border-y">
          <div
            class="tw-flex tw-cursor-pointer tw-items-center tw-space-x-3 tw-p-5 tw-text-base-600 hover:tw-bg-base-50 hover:tw-text-base-900"
            v-for="(action, i) in quickActions"
            :key="i"
            @click.prevent="
              () => {
                action.action();
                isQuickActionModal = false;
              }
            "
          >
            <AppIcon :name="action.icon" />
            <span>{{ action.name }}</span>
          </div>
        </div>

        <div>
          <NuxtLink to="/dashboard/support">
            <div class="tw-p-5 tw-text-center tw-font-medium">
              <span>Contact Support</span>
            </div>
          </NuxtLink>
        </div>
      </div>
    </transition>

    <!-- Wallet Creation -->
    <FinanceCreateWallet
      :isActive="isWalletModalActive"
      @close="closeWalletModal"
      @wallet-created="handleWalletCreation"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
const { $router, $eventBus } = useNuxtApp();

const isWalletModalActive = ref<boolean>(false);
const openWalletModal = () => (isWalletModalActive.value = true);
const closeWalletModal = () => (isWalletModalActive.value = false);
const handleWalletCreation = () => {
  closeWalletModal();
  $router.push("/dashboard/finance/wallets");
};

// Actions
const viewInvoicePayments = () => {
  $router.push("/dashboard/payments/invoices");
};

const createInvoice = () => {
  $router.push("/dashboard/bursary/collections/create-invoice");
};

const createWallet = () => {
  openWalletModal();
};

const reconcileOfflinePayments = () => {
  $router.push("/dashboard/bursary/invoices");
};

const createBillingSession = () => {
  $router.push("/dashboard/bursary/billing");
};

const addBillingAccount = () => {
  $router.push("/dashboard/admin/billing-accounts");
};

const openSearchModal = () => {
  $eventBus.$emit("openSearchModal");
};

const quickActions = ref([
  {
    icon: "receipt",
    name: "Create new invoice",
    action: () => {
      createInvoice();
    },
  },
  {
    icon: "wallet-04",
    name: "Create new wallet",
    action: () => {
      createWallet();
    },
  },
  {
    icon: "eye",
    name: "View invoice payments",
    action: () => {
      viewInvoicePayments();
    },
  },
  {
    icon: "calendar",
    name: "Create new billing session",
    action: () => {
      createBillingSession();
    },
  },
  {
    icon: "user-plus-01",
    name: "Add Billing Account",
    action: () => {
      addBillingAccount();
    },
  },
  {
    icon: "credit-card-refresh",
    name: "Reconcile offline payment",
    action: () => {
      reconcileOfflinePayments();
    },
  },
  {
    icon: "search-md",
    name: "Quick search",
    action: () => {
      openSearchModal();
    },
  },
]);

const isQuickActionModal = ref(false);

const openQuickAction = () => {
  isQuickActionModal.value = true;
};

const quickActionsContainer = ref(null);

onClickOutside(quickActionsContainer, () => {
  isQuickActionModal.value = false;
});

// Transition hooks for animation timing
const beforeEnter = (el: Element) => {
  el.setAttribute("style", "opacity: 0; transform: translateY(10px);");
};
const enter = (el: Element, done: () => void) => {
  setTimeout(() => {
    el.setAttribute(
      "style",
      "transition: all 0.3s ease; opacity: 1; transform: translateY(0);",
    );
    done();
  });
};
const leave = (el: Element, done: () => void) => {
  el.setAttribute(
    "style",
    "transition: all 0.2s ease; opacity: 0; transform: translateY(10px);",
  );
  setTimeout(done, 200);
};
</script>

<style scoped>
/* Scoped styles for quick-action transition */
.quick-action-enter-active,
.quick-action-leave-active {
  transition: all 0.3s ease;
}
.quick-action-enter-from,
.quick-action-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
