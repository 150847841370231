<template>
  <div>
    <div v-if="theme === 'dark'">
      <div class="tw-text-primary tw-space-y-2 tw-py-4">
        <div v-for="(route, i) in routes" :key="i">
          <div
            :id="route.id"
            :title="route.title"
            class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded tw-text-gray-50"
          >
            <div v-if="route.children" :id="route.id" class="tw-w-full">
              <div
                @click.prevent="toggleSubMenu(route)"
                class="tw-flex tw-w-full tw-items-center tw-justify-start tw-rounded tw-px-3 tw-py-2 hover:tw-bg-gray-800 lg:tw-justify-between"
              >
                <div
                  class="tw-flex tw-items-center tw-space-x-3 lg:tw-ml-0 xl:tw-justify-start"
                  :class="[isExpanded ? 'tw-w-full' : '']"
                >
                  <AppIcon :name="route.icon" />
                  <h3 :class="[isExpanded ? '' : 'tw-hidden']">
                    {{ route.title }}
                  </h3>
                </div>

                <div v-if="isExpanded">
                  <Icon
                    name="ph:caret-down-bold"
                    :class="[
                      isExpanded ? '' : 'xl:tw-invisible',
                      route.showChildren?.value
                        ? 'tw-rotate-180 tw-transition-all tw-duration-300 tw-ease-in-out'
                        : '',
                    ]"
                  />
                </div>
              </div>

              <div
                v-if="route.showChildren?.value"
                :class="[isExpanded ? '' : 'xl:tw-hidden']"
              >
                <NuxtLink
                  v-for="(subRoute, index) in route.children"
                  :key="index"
                  class="tw-flex tw-w-full tw-items-center tw-rounded tw-px-3 tw-py-2 hover:tw-bg-gray-800"
                  :to="subRoute.to"
                  exact
                >
                  <h3 class="tw-pl-8 xl:tw-block">
                    {{ subRoute.title }}
                  </h3>
                </NuxtLink>
              </div>
            </div>

            <div class="tw-w-full" v-else-if="route.title == 'Notifications'">
              <div
                @click.prevent="showNotifications()"
                class="tw-flex tw-w-full tw-items-center tw-space-x-3 tw-rounded tw-px-3 tw-py-2 tw-text-gray-50 hover:tw-bg-gray-800 lg:tw-ml-0 xl:tw-justify-start"
              >
                <AppIcon :name="route.icon" />
                <h3
                  class="tw-flex-grow"
                  :class="[isExpanded ? '' : 'tw-hidden']"
                >
                  {{ route.title }}
                </h3>
                <div
                  :class="[isExpanded ? '' : 'tw-hidden']"
                  class="tw-grid tw-h-[25px] tw-w-[30px] tw-place-content-center tw-rounded-full tw-bg-white tw-font-semibold tw-text-gray-800"
                >
                  {{ store.totalUnreadNotifications }}
                </div>
              </div>
            </div>

            <div :class="[isExpanded ? 'tw-w-full' : '']" v-else>
              <NuxtLink
                class="tw-flex tw-w-full tw-items-center tw-space-x-3 tw-rounded tw-px-3 tw-py-2 tw-text-gray-50 hover:tw-bg-gray-800 lg:tw-ml-0 xl:tw-justify-start"
                :class="[isExpanded ? 'tw-w-full' : '']"
                :to="route.to"
                exact
              >
                <AppIcon :name="route.icon" />
                <h3 :class="[isExpanded ? '' : 'tw-hidden']">
                  {{ route.title }}
                </h3>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="theme === 'light'">
      <div class="tw-text-primary tw-space-y-2 tw-py-4">
        <div v-for="(route, i) in routes" :key="i">
          <div
            :id="route.id"
            :title="route.title"
            class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded"
          >
            <div v-if="route.children" class="tw-w-full">
              <div
                @click.prevent="toggleSubMenu(route)"
                class="tw-flex tw-w-full tw-items-center tw-justify-start tw-rounded tw-px-3 tw-py-2 hover:tw-bg-gray-200 lg:tw-justify-between"
              >
                <div
                  class="tw-flex tw-w-full tw-items-center tw-space-x-3 lg:tw-ml-0 xl:tw-justify-start"
                >
                  <AppIcon :name="route.icon" />
                  <h3 class="xl:tw-block">{{ route.title }}</h3>
                </div>
                <Icon
                  name="ph:caret-down-bold"
                  :class="[
                    isExpanded ? '' : 'xl:tw-invisible',
                    route.showChildren?.value
                      ? 'tw-rotate-180 tw-transition-all tw-duration-300 tw-ease-in-out'
                      : '',
                  ]"
                />
              </div>

              <div
                v-if="route.showChildren?.value"
                :class="[isExpanded ? '' : 'xl:tw-hidden']"
              >
                <NuxtLink
                  v-for="(subRoute, index) in route.children"
                  :key="index"
                  class="tw-flex tw-w-full tw-items-center tw-rounded tw-px-3 tw-py-2 hover:tw-bg-gray-200"
                  :to="subRoute.to"
                  exact
                >
                  <h3 class="tw-pl-8 xl:tw-block">
                    {{ subRoute.title }}
                  </h3>
                </NuxtLink>
              </div>
            </div>

            <div
              class="tw-hidden tw-w-full"
              @click.prevent="showNotifications()"
              v-else-if="route.title == 'Notifications'"
            >
              <div
                class="tw-flex tw-w-full tw-items-center tw-space-x-3 tw-rounded tw-px-3 tw-py-2 tw-text-gray-50 hover:tw-bg-gray-800 lg:tw-ml-0 xl:tw-justify-start"
              >
                <AppIcon :name="route.icon" />
                <h3 class="tw-flex-grow">
                  {{ route.title }}
                </h3>
                <div
                  class="tw-grid tw-h-[25px] tw-w-[30px] tw-place-content-center tw-rounded-full tw-bg-white tw-font-semibold tw-text-gray-800"
                >
                  {{ store.totalUnreadNotifications ?? 0 }}
                </div>
              </div>
            </div>

            <div class="tw-w-full" v-else>
              <NuxtLink
                class="tw-flex tw-w-full tw-items-center tw-space-x-3 tw-rounded tw-px-3 tw-py-2 hover:tw-bg-gray-200 lg:tw-ml-0 xl:tw-justify-start"
                :to="route.to"
                exact
              >
                <AppIcon :name="route.icon" />
                <h3 class="xl:tw-block">{{ route.title }}</h3>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "~/store/";
const { $eventBus } = useNuxtApp();
const store = useStore();
interface ThemeType {
  theme?: "light" | "dark";
  isExpanded?: Boolean;
}

interface RouteContent {
  icon: string;
  title: string;
  to: string;
  id?: string;
  children?: [
    {
      title: string;
      to: string;
    },
  ];
  showChildren?: Ref<boolean>;
}

defineProps<ThemeType>();

const toggleSubMenu = (item: any) => {
  if (item.children) {
    item.showChildren.value = !item.showChildren.value;
  }
};

const routes: RouteContent[] = [
  {
    icon: "bell-01",
    title: "Notifications",
    to: "/dashboard/notifications",
    id: "notifications",
  },
  {
    icon: "bar-chart-01",
    title: "Support",
    to: "/dashboard/support",
    id: "support",
  },
  // {
  //   icon: "life-buoy-01",
  //   title: "iRecovery",
  //   to: "/dashboard/irecovery",
  // },
  {
    icon: "settings-01",
    title: "Settings",
    id: "settings",
    to: "/dashboard/settings",
  },
];

const showNotifications = () => {
  $eventBus.$emit("showNotifications");
};
</script>

<style lang="scss" scoped>
// Dark Mode (Desktop Side Navigation)
.router-link-active #dark {
  color: theme("colors.gray.100");
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.800");
  width: 100%;
  padding: 0.3rem 0;
}
/* exact link will show the primary color for only the exact matching link */
.router-link-exact-active #dark {
  color: white;
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.800");
  width: 100%;
  padding: 0.5rem 0;
}

// Light Mode (Mobile Side Navigation)
.router-link-active #light {
  color: theme("colors.gray.700");
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.200");
  width: 100%;
  padding: 0.3rem 0;
}
/* exact link will show the primary color for only the exact matching link */
.router-link-exact-active #light {
  color: theme("colors.gray.700");
  font-weight: 600;
  border-radius: 6px;
  background: theme("colors.gray.200");
  width: 100%;
  padding: 0.5rem 0;
}
</style>
