<template>
  <div>
    <!-- CREATE PIN MODAL -->
    <Modal
      :is-active="setPinPrompt"
      @close="closeCreatePinModal"
      :disable-overlay-close="false"
    >
      <!-- Step One -->
      <section v-if="step === 1" class="lg:tw-max-w-[620px]">
        <div class="tw-space-y-5">
          <AppFeaturedIcon icon="alert-triangle" theme="warning" />

          <div
            class="tw-grid tw-grid-cols-1 tw-items-center md:tw-grid-cols-12 lg:tw-grid-cols-12"
          >
            <div class="tw-space-y-5 md:tw-col-span-6 lg:tw-col-span-8">
              <div class="tw-space-y-3">
                <h5 class="tw-text-3xl tw-font-semibold lg:tw-text-4xl">
                  You have not set a security PIN
                </h5>
                <p class="tw-text-gray-500">
                  Set a four-digit PIN code to protect your account and secure
                  your transactions on Sproutly
                </p>
              </div>

              <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                <AppButton type="dark" @click.prevent="step = 2"
                  >Set PIN</AppButton
                >
                <AppButton type="default" @click.prevent="closeCreatePinModal"
                  >Maybe Later</AppButton
                >
              </div>
            </div>
            <div
              class="tw-order-first md:tw-order-last md:tw-col-span-6 lg:tw-col-span-4"
            >
              <div
                class="tw-m-auto tw-w-[120px] md:tw-w-[150px] lg:tw-w-[150px]"
              >
                <img
                  src="~/assets/images/auth/shield.svg"
                  alt="shield"
                  class="tw-h-full tw-w-full tw-object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Step Two -->
      <section
        v-if="step === 2"
        class="tw-overflow-y-scroll lg:tw-max-w-[600px]"
      >
        <form @submit.prevent="createPin" class="tw-max-h-[500px] tw-space-y-5">
          <AppFeaturedIcon icon="lock-01" />

          <div class="tw-space-y-3">
            <h5 class="tw-text-xl tw-font-semibold tw-text-gray-900">
              Set a security PIN
            </h5>

            <p class="tw-text-gray-500">
              Enter a four-digit code that will used to secure your transactions
            </p>
          </div>

          <div class="form-group">
            <AppInput
              :required="true"
              type="password"
              label="New PIN"
              placeholder="****"
              maxLength="4"
              minLength="4"
              min="0"
              max="9999"
              v-model="pin"
              error-label="pin"
              :errors="errors"
              pattern="[0-9]+"
              title="Please enter only numerical characters"
              :allow-only-number="true"
            />
          </div>
          <div class="form-group">
            <AppInput
              :required="true"
              type="password"
              label="Confirm PIN"
              placeholder="****"
              minlength="4"
              maxlength="4"
              min="0"
              max="9999"
              v-model="confirm_pin"
              error-label="pin"
              :errors="errors"
              pattern="[0-9]+"
              title="Please enter only numerical characters"
              :allow-only-number="true"
            />
          </div>

          <div class="form-group">
            <AppInput
              :required="true"
              type="password"
              label="Password"
              placeholder="************"
              v-model="password"
              error-label="password"
              :errors="errors"
            />
          </div>

          <div class="tw-grid tw-grid-cols-2 tw-gap-5">
            <AppButton
              type="outline"
              :full="true"
              @click.prevent="closeCreatePinModal"
              >Maybe later</AppButton
            >
            <AppButton type="dark" :full="true" button-type="submit"
              >Set PIN</AppButton
            >
          </div>
        </form>
      </section>

      <!-- Step Three -->
      <section v-if="step === 3">
        <div class="tw-space-y-5">
          <div class="tw-m-auto tw-w-max">
            <AppFeaturedIcon icon="check-circle" theme="success" />
          </div>

          <div class="tw-space-y-3 tw-text-center">
            <h5 class="tw-text-xl tw-font-semibold tw-text-gray-900">
              Your PIN has been saved
            </h5>
            <p class="tw-text-gray-500">
              You can change your PIN code in Settings > Security > Change PIN
            </p>
          </div>

          <div class="tw-m-auto tw-w-max">
            <AppButton type="dark" @click.prevent="closeCreatePinModal()"
              >Continue</AppButton
            >
          </div>
        </div>
      </section>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "~/store/index";
const { $axios, $toast } = useNuxtApp();
const nuxtApp = useNuxtApp();

const store = useStore();

withDefaults(
  defineProps<{
    setPinPrompt: boolean;
  }>(),
  {
    setPinPrompt: true,
  },
);

const errors = ref<object[]>([]);
const password = ref<string>("");
const confirm_pin = ref<string>("");
const pin = ref<string>("");

// Step
const step = ref<number>(1);

// PIN management

const closeCreatePinModal = () => {
  store.updateSetPinPrompt(false);
  localStorage.setItem("spvRemindLater", "true");
};

const createPin = async () => {
  if (pin.value === confirm_pin.value) {
    try {
      nuxtApp.callHook("page:start");

      const response = await $axios.post(
        `/schools/setting/security/pin/create`,
        {
          pin: pin.value,
          password: password.value,
        },
        {
          headers: {
            "x-authorize": password.value,
          },
        },
      );

      const { status, message } = response.data;

      if (status === true || status === "successful") {
        pin.value = "";
        confirm_pin.value = "";
        password.value = "";

        step.value = 3;
        $toast.success(message);
      }
    } catch (error: any) {
      if (error.response) {
        password.value = "";
        $toast.error(error.response.data.message);
        errors.value = error.response.data?.errors;
      }
    } finally {
      nuxtApp.callHook("page:finish");
    }
  } else {
    $toast.error("PIN does not match");
  }
};
</script>

<style scoped></style>
