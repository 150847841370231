<template>
  <div>
    <!-- Search Modal -->
    <Modal :is-active="isSearchModalActive" @close="closeSearchModal()">
      <div class="tw-space-y-5">
        <section class="tw-m-auto lg:tw-min-w-[500px]">
          <div>
            <form action="">
              <div class="tw-flex tw-items-center tw-space-x-2">
                <AppInput
                  type="text"
                  placeholder="What are you looking for?"
                  class="tw-w-full"
                  leading-icon="lucide:search"
                />
                <AppButton type="dark" size="medium">Search</AppButton>
              </div>
            </form>
          </div>
        </section>

        <!-- Empty State -->
        <section class="custom-card">
          <EmptyPage
            title="Search"
            icon="file-search-02"
            description="Start typing to search through your data. You can look up billing accounts, billing managers, invoices or any relevant content."
          />

          <!-- <EmptyPage
            title="No Results Found"
            icon="file-search-02"
            description="We couldn't find anything matching your search. Try adjusting your search terms or check your spelling."
          /> -->
        </section>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
const { $eventBus } = useNuxtApp();

// Search Modal
const isSearchModalActive = ref<boolean>(false);
const openSearchModal = () => (isSearchModalActive.value = true);
const closeSearchModal = () => (isSearchModalActive.value = false);

// Listen for Event to Open Search Modal
onMounted(() => {
  $eventBus.$on("openSearchModal", () => {
    openSearchModal();
  });
});
</script>

<style scoped></style>
