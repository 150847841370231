<template>
  <div>
    <!-- Search Field -->
    <div
      @click.prevent="openSearchModal()"
      :class="[
        isExpanded
          ? 'tw-flex tw-items-center'
          : 'tw-grid tw-place-content-center',
        theme === 'dark'
          ? 'tw-bg-gray-700 tw-text-white'
          : 'tw-bg-gray-100 tw-text-gray-900',
      ]"
      class="tw-cursor-pointer tw-gap-4 tw-rounded-lg tw-p-2"
    >
      <AppIcon name="search-md" class="tw-px-1" />
      <span :class="[isExpanded ? 'lg:tw-block' : 'tw-hidden']">Search</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const { $eventBus } = useNuxtApp();

withDefaults(
  defineProps<{
    isExpanded: boolean;
    theme: string;
  }>(),
  {
    theme: "dark",
  },
);

const openSearchModal = () => {
  $eventBus.$emit("closeMobileSideMenu");
  $eventBus.$emit("openSearchModal");
};
</script>

<style scoped></style>
